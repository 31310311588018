// Enter variables here

// Font variables
$base-fontsize: 16px;

// Colors variables

$color-white: #ffffff;
$color-black: #333333;
$color-dark: #131211;

$color-brand: #DD6B26;
$iu-1: #FEFBF8;
$iu-2: #FBFBFB;
$iu-3: #FAFAFA;
$iu-g-1: #7d7d7d;

// Typeface variables
$font1-light: 'Open Sans', sans-serif;
$font1-reg: 'Open Sans', sans-serif;
$font1-bold: 'Open Sans', sans-serif;
$font2-reg: 'DolceVita-Regular';