.article-card {
  margin-bottom: 4rem;

  &:hover h4{
      color: $color-brand;
    }

  img {
    width: 100%;
    max-width: 100%;
  }

  h4 {
    margin-top: pxToRem(24px);
    margin-bottom: pxToRem(12px);
    font-family: $font1-reg;
    font-size: pxToRem(20px);
    line-height: 160%;
    color: $color-black;
    font-weight: bold; 
  }

  p {
    font-size: 1em;
    color: #666;
    line-height: 180%;
    width: 100%;
    max-width: 100%;
  }
}

.article-card-img {
  height: 250px;
  width: 100%;
  background: url(/img/lobby1.jpg) no-repeat center center;
  background-size: cover;
  margin-bottom: 1rem;
}
