.blog-page {

	.container {
		// width: 770px;
		// max-width: 100%!important;


		h1 {
			margin-top: pxToRem(100px);
		}
	}
}

.container.article-parent {
	// width: 1140px;
	margin-top: 40px;

	img {
		width: 100%;
		max-width: 100%;
	}

	.article-wrap {
		// width: 770px;
		margin: 0 auto;

		.summary {
			line-height: 180%;
			font-weight: 300;
			font-size: pxToRem(18px);
		}

		.article-body {
			h2 {
				font-size: pxToRem(21px);
				font-weight: bold;
				margin: 32px 0px 21px 0px;
			}

			p {
				font-weight: 300;

			}
		}
	}
}

.back-button {
	display: block;
	margin: 150px auto;
	text-align: center;
	color: $color-brand!important;
	text-transform: uppercase;
	font-size: pxToRem(14px);
	letter-spacing: 2px;
}

.pagination {
	display: flex;
    margin: 50px 0 100px;
    justify-content: space-between;

    .prev-page,
    .next-page {
    	text-transform: uppercase;
		font-size: pxToRem(14px);
		letter-spacing: 2px;
    	width: 100%;
    	font-weight: 300;

    	a {
    		color: $color-black;
    	}

    	a:hover {
    		color: $color-brand;	
    	}
    }

    .next-page {
    	text-align: right;
    }

}

blockquote {
  border-left: 3px solid $color-brand;
  color: #1a1a1a;  
  font-size: 1.25em;
  font-style: italic;
  line-height: 1.8em;
  margin: 1.1em 0;
  padding: 1em 2em;
  position: relative;
  transition: 0.2s border ease-in-out;
  z-index: 0;

  p {
  	font-weight: 400!important;
  }
}
blockquote:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -4px;
  height: 2em;
  background-color: #fafafa;
  width: 5px;
  margin-top: -1em;
}
blockquote:after {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 50px;
  content: "\201C";
  position: absolute;
  top: 50%;
  left: -28px;
  color: $color-brand;
  line-height: 1em;
  text-align: center;
  text-indent: -2px;
  width: 1em;
  margin-top: -12px;
  transition: all .2s ease-in-out,transform .4s ease-in-out;
}

