.navbar {

  box-shadow: 0 -3px rgba(0,0,0,0.15) inset;
  padding: 0 1rem;

  .navbar-container {
    padding: 0px;
  }

  .navbar-brand {

    padding: 0px 0px 3px 0px;

    img {
      height: 100px;
    }
  }

  .nav-link {
    font-size: pxToRem(14px);
    color: rgba(0,0,0,0.5)!important;
    font-weight: bold;

    &:hover,
    &.active {
      opacity: 0.6;
    }
  }

  .navbar-toggler {
    border: 1px solid #e3e3e3;
    padding: .75rem;
  }

  .navbar-toggler-icon {
    opacity: 0.5;
    height: 1em;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHMAAABkCAMAAACCTv/3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFAAAA5ubmSUUG+gAAAAJ0Uk5T/wDltzBKAAAAPklEQVR42uzYQQ0AAAgDseHfNC4IyVoD912WAACUm3uampqampqamq+aAAD+IVtTU1NTU1NT0z8EAFBsBRgAX+kR+Qam138AAAAASUVORK5CYII=);
  }
}


// .navbar-main {
//   // position: absolute;
//   width: 100%;

//   .navbar-brand {
//     padding: 0;

//     img {
//       width: 90px;
//     }
//   }

//   .nav-toggle {
//     color: $color-white;
//     font-family: $font1-reg;
//     text-transform: uppercase;
//     letter-spacing: 1px;
//     font-size: pxToRem(14px);
//     line-height: 2rem;

//     i {
//       margin-left: 5px;
//     }

//     &:hover {
//       text-decoration: none;
//       position: relative;
//       top: -2px;
//     }
//   }

//   &.--scrolled {
//     position: fixed;
//     background-color: $color-white;
//     z-index: 2;
//     padding-top: .8rem;
//     padding-bottom: .8rem;
//     border-bottom: 1px solid rgba(#000, .06);

//     @include breakpoint(md) {
//       padding-top: 1rem;
//       padding-bottom: 1rem;
//     }

//     .navbar-brand {
//       img {
//         height: 40px;
//         width: auto;

//         @include breakpoint(md) {
//           height: 50px;
//         }
//       }
      
//     }

//     .nav-toggle {
//       color: rgba(#000, .2);
//     }
//   }
// }