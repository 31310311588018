// --------------------------
//  HELPER CLASSES
// --------------------------

.auto-960 {
  max-width: 960px;
  margin: 0 auto;
}

.auto-1110 {
  max-width: 1110px;
  margin: 0 auto;
}

.auto-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

.mt0 {
  margin-top: 0px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mt90 {
  margin-top: 90px !important;
}
.mt100 {
  margin-top: 100px !important;
}

.mb-2 {
  margin-bottom: -2px!important;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.mb70 {
  margin-bottom: 70px !important;
}
.mb80 {
  margin-bottom: 80px !important;
}
.mb90 {
  margin-bottom: 90px !important;
}
.mb100 {
  margin-bottom: 100px !important;
}
.ml0 {
  margin-left: 0px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml50 {
  margin-left: 50px !important;
}
.ml60 {
  margin-left: 60px !important;
}
.ml70 {
  margin-left: 70px !important;
}
.ml80 {
  margin-left: 80px !important;
}
.ml90 {
  margin-left: 90px !important;
}
.ml100 {
  margin-left: 100px !important;
}
.mr0 {
  margin-right: 0px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr60 {
  margin-right: 60px !important;
}
.mr70 {
  margin-right: 70px !important;
}
.mr80 {
  margin-right: 80px !important;
}
.mr90 {
  margin-right: 90px !important;
}
.mr100 {
  margin-right: 100px !important;
}
.m0 {
  margin: 0px !important;
}
.m10 {
  margin: 10px !important;
}
.m20 {
  margin: 20px !important;
}
.m30 {
  margin: 30px !important;
}
.m40 {
  margin: 40px !important;
}
.m50 {
  margin: 50px !important;
}
.m60 {
  margin: 60px !important;
}
.m70 {
  margin: 70px !important;
}
.m80 {
  margin: 80px !important;
}
.m90 {
  margin: 90px !important;
}
.m100 {
  margin: 100px !important;
}

.pt0 {
  padding-top: 0px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pt60 {
  padding-top: 60px !important;
}
.pt70 {
  padding-top: 70px !important;
}
.pt80 {
  padding-top: 80px !important;
}
.pt90 {
  padding-top: 90px !important;
}
.pt100 {
  padding-top: 100px !important;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pb60 {
  padding-bottom: 60px !important;
}
.pb70 {
  padding-bottom: 70px !important;
}
.pb80 {
  padding-bottom: 80px !important;
}
.pb90 {
  padding-bottom: 90px !important;
}
.pb100 {
  padding-bottom: 100px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pl60 {
  padding-left: 60px !important;
}
.pl70 {
  padding-left: 70px !important;
}
.pl80 {
  padding-left: 80px !important;
}
.pl90 {
  padding-left: 90px !important;
}
.pl100 {
  padding-left: 100px !important;
}
.pr0 {
  padding-right: 0px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pr60 {
  padding-right: 60px !important;
}
.pr70 {
  padding-right: 70px !important;
}
.pr80 {
  padding-right: 80px !important;
}
.pr90 {
  padding-right: 90px !important;
}
.pr100 {
  padding-right: 100px !important;
}
.p0 {
  padding: 0px !important;
}
.p10 {
  padding: 10px !important;
}
.p20 {
  padding: 20px !important;
}
.p30 {
  padding: 30px !important;
}
.p40 {
  padding: 40px !important;
}
.p50 {
  padding: 50px !important;
}
.p60 {
  padding: 60px !important;
}
.p70 {
  padding: 70px !important;
}
.p80 {
  padding: 80px !important;
}
.p90 {
  padding: 90px !important;
}
.p100 {
  padding: 100px !important;
}

@media (min-width: 768px) {
  .p30-md {
    padding: 30px !important;
  }
  .pl80-md {
    padding-left: 80px !important;
  }
}

.mh-100vh {
  min-height: 100vh !important;
}

@include breakpoint(md) {
  .mh-100vh-md {
    min-height: 100vh !important;
  }
}

.h-400 {
  height: 400px !important;
}

.mb120 {
  margin-bottom: 120px !important;
}

.bg-cover {
  background: url(/img/bloq-surfer.jpg) no-repeat center center;
  background-size: cover;
}

// COLOR HELPERS
.c-white {
  color: $color-white !important;
}

.c-brand {
  color: $color-brand !important;
}
.c-black-o-50 {
  color: rgba($color-black, .5) !important;
}

.bg-brand {
  background-color: $color-brand !important;
}

.bg-ui-1 {
  background-color: $iu-1 !important;
}

.bg-ui-2 {
  background-color: $iu-2 !important;
}

.font-brand {
  font-family: $font2-reg !important;
}