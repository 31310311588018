.footer {
  margin: 70px auto;

  .footer-items {

    > div {
      display: inherit;
    }

    .footer-logo {
      height: 100px;
    }

    .footer-address {
      font-size: pxToRem(11px);
      margin-left: 15px;
    }

    .footer-center-text {
      font-size: pxToRem(12px);
      color: #999;
      margin: 30px 0 0;

      a {
        color: $color-black;
        text-decoration: underline;
      }      

    }

    .footer-links {
      margin-top: 30px;

      .fab {
          color: #666;
          font-size: 35px!important;
      }
    }
  }
}